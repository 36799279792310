<template>
    <div v-loading="is_panding" class="ben-shlush-dashboard">
        <div  class="ben-shlush-dashboard-wrapper">
            <div class="ben-shlush-dashboard-wrapper-title">
                <h2>ניהול עמוד מבצעים - בן שלוש</h2>
            </div>
            <div class="ben-shlush-dashboard-wrapper-tools">
                <el-button @click="show_create_sale = true" type="success">הוסף מבצע</el-button>
                <el-input style="width: 30%; margin-right: 5px;" v-model="search" placeholder="חיפוש מבצע..." />
            </div>
            <div class="ben-shlush-dashboard-wrapper-content">
                <h2 v-if="!sales.length">לא קיימים מבצעים!</h2>
                <template v-for="sale in sales" :key="sale.uid">
                    <SaleCard @delete="handle_delete_sale" @edit="handle_selected_sale_to_edit" :sale="sale"/>
                </template>
            </div>
        </div>
        <CreateNewSale 
            @submit="handle_submit" 
            @edit_submit = "handle_edit_submit"
            @close="show_create_sale=false; selected_sale_to_edit=null" 
            :selected_edit="selected_sale_to_edit"
            v-if="show_create_sale" 
        />
    </div>
</template>

<script>
import {ref, defineAsyncComponent} from 'vue'
import SaleCard from '../../components/BenShlush/SaleCard.vue'
import {get_sales_form_db} from '../../components/BenShlush/scripts'
import {slide_pop_error} from '../../Methods/Msgs'


export default {
    components:{
        SaleCard,
        CreateNewSale:defineAsyncComponent(() => import('../../components/BenShlush/CreateNewSale.vue'))
        
    },
    setup () {
        
        const sales = ref([])
        const search = ref('')
        const is_panding = ref(false)
        const show_create_sale = ref(false)
        const selected_sale_to_edit = ref(null)

        const handle_submit = (new_sale) => {
            sales.value.unshift(new_sale)
        }

        const handle_edit_submit = (sale) => {
            const index = sales.value.findIndex(({uid}) => uid == sale.uid)
            if(index != -1){
                sales.value[index] = sale
            }
        }

        const handle_selected_sale_to_edit = (edit_sale) => {
            selected_sale_to_edit.value = edit_sale
            show_create_sale.value = true
        }

        const handle_delete_sale = (sale) => {
            const index = sales.value.findIndex(({uid}) => uid == sale.uid)
            if(index != -1){
                sales.value.splice(index,1)
            }
        }

        const init = async() => {
            try{
                is_panding.value = true
                sales.value = await get_sales_form_db()
                console.log(sales.value);
                is_panding.value = false
            }catch(err){
                is_panding.value = false
                console.error(err.message);
                slide_pop_error(`שגיאה במשיכת נתונים - ${err.message}`)
            }
        }

        init()
        return {
            search,
            is_panding,
            sales,
            show_create_sale,
            handle_submit,
            selected_sale_to_edit,
            handle_selected_sale_to_edit,
            handle_edit_submit,
            handle_delete_sale
        }
    }
}
</script>

<style scoped>
    .ben-shlush-dashboard{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 5px;
        background: #F2F3F8;
    }
    .ben-shlush-dashboard-wrapper{
        width: 100%;
        max-width: 1200px;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
    .ben-shlush-dashboard-wrapper-title{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .ben-shlush-dashboard-wrapper-tools{
        width: 100%;
        height: 50px;
        padding: 0 5px;
        display: flex;
        align-items: center;
    }
    .ben-shlush-dashboard-wrapper-content{
        width: 100%;
        flex: 1;
        overflow: hidden;
        overflow-y: auto;
    }
</style>