<template>
    <div class="item-card">
        <div class="item-card-content">
            <div class="item-card-content-image">
                <img v-if="sale.image" :src="sale.image" alt="">
                <img v-else :src="defualt_photo" alt="">
            </div>
            <div class="item-card-content-details">
                <div class="item-card-content-details-title">
                    <h2>{{sale.name}}</h2>
                    <i @click="handle_edit_sale" class="pi pi-pencil icon icon-edit"></i>
                    <i @click="handle_delete_sale" class="pi pi-trash icon icon-trash"></i>
                </div>
                <div class="item-card-content-details-desc">
                    <p class="desc-text">
                        {{sale.description}}
                    </p>
                    <span class="price_and_amount">
                        כמות במארז:{{sale.pac_amount}}, מחיר ליח': {{Number(sale.unit_price).toFixed(2)}} &#8362;
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import defualt_photo from '../../assets/images/defualt_photo.png'
    import {alert_confirm} from '../../Methods/Msgs'
    import {delete_sale_from_db} from './scripts'
    import {ref} from 'vue'
    export default {
        emits:['edit','delete'],
        props:['sale'],
        setup(props,{emit}){

            const selectes_sale_to_edit = ref(null)

            const handle_edit_sale = () => {
                selectes_sale_to_edit.value = JSON.parse(JSON.stringify(props.sale));
                emit('edit',selectes_sale_to_edit.value)
            }

            const handle_delete_sale = () => {
                alert_confirm(`למחוק את ${props.sale.name}?`)
                .then(async res => {
                    if(res.isConfirmed){
                        await delete_sale_from_db(props.sale)
                        emit('delete',props.sale)
                    }
                })
            }

            return{
                defualt_photo,
                selectes_sale_to_edit,
                handle_edit_sale,
                handle_delete_sale
            }
        }
    }
</script>

<style scoped>
    .item-card{
        width: 100%;
        height: 150px;
        border-radius: 10px;
        background: #fff;
        display: flex;
        flex-direction: column;
        margin-bottom: 5px;
    }
  
    .item-card-content{
        width: 100%;
        flex: 1;
        display: flex;
    }
    .item-card-content-image{
        width: 200px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media only screen and (max-width: 600px){
        .item-card-content-image{
            width: 100px;
        }
    }
    .item-card-content-image img{
        max-width: 60%;
        max-height: 100%;
    }
    .item-card-content-details{
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .item-card-content-details-title{
        position: relative;
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        text-align: center;
        border-bottom: 1px solid lightblue;
    }
    .item-card-content-details-desc{
        position: relative;
        width: 100%;
        flex: 1;
    }
    .price_and_amount{
        position: absolute;
        bottom: 5px;
        left: 5px;
        color: #6c757d;

    }
    .desc-text{
        font-size: 18px;
        white-space: pre-wrap;
    }
    .icon{
        position: absolute;
        user-select: none;
        cursor: pointer;
        font-size: 20px;
    }
    .icon-edit,.icon-trash{
        color: var(--warning);
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
    }
    .icon-trash{
        color: var(--danger);
        left: 35px;
    }




</style>